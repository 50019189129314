import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { RedirectGuard } from './core/guards/redirect.guard';
import { SwitchLayout } from './layouts/switch/switch.layout';

const routes: Routes = [
    // TODO: if team or team member, send them to teams, else to artists
    {   path: 'invite',    redirectTo: 'onboard/professional',       pathMatch: 'full' },
    {   path: 'hello',     redirectTo: 'onboard/professional',       pathMatch: 'full' },

    {   path: 'creatives', redirectTo: 'artists',       pathMatch: 'full' },
    {   path: 'login',     redirectTo: 'auth/login',    pathMatch: 'full' },
    {   path: 'register',  redirectTo: 'auth/register', pathMatch: 'full' },
    {   path: 'dashboard', redirectTo: 'switch',        pathMatch: 'full' },

    {
        path: '',
        loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'landing',
        loadChildren: () => import('./public/landing/landing.module').then( m => m.LandingModule)
    },
    {
        path: 'legal',
        loadChildren: () => import('./public/legal/legal.module').then(m => m.LegalModule)
    },
    {
        path: 'cards',
        loadChildren: () => import('./public/cards/cards.module').then(m => m.CardsModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'onboard',
        loadChildren: () => import('./modules/onboard/onboard.module').then(m => m.OnboardModule)
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
    },
    {
        path: 'app',
        canActivate: [ AuthGuard ],
        loadChildren: () => import('./modules/tabs/tabs.module').then(m => m.TabsModule)
    },
    {
        path: 'switch',
        canActivate: [ AuthGuard ],
        component: SwitchLayout,
        loadChildren: () => import('./modules/switch/switch.module').then(m => m.SwitchModule) //, resolve: [ SwitchResolver ]
    },
    // {
	// 	path: 'hello',
	// 	canActivate: [RedirectGuard],
	// 	component: RedirectGuard,
	// 	data: {
	// 		externalUrl: 'https://wow.mt/clive'
	// 	}
	// },
	{
		path: 'q/EFABC2FD-66BD-444A-A23D-7DA071AAC9DF',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/frank'
		}
	},
	{
		path: 'q/88BF1C2D-2D63-4806-8A7E-64D25E96141D',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/deanmicallef'
		}
	},
	{
		path: 'q/8581B1EA-9E3F-4C77-A390-DBC212461153',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/audreycaruana'
		}
	},
	{
		path: 'q/3E9919B1-070F-4CCB-BD23-F4809992CF62',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/rapamanche'
		}
	},
	{
		path: 'q/7330DDD2-A701-4764-A783-90C5F6A43665',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/davidvella'
		}
	},
	{
		path: 'q/6BC47CEF-514B-44DD-A0AB-C1DA64C57067',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://wow.mt/parnisengland'
		}
	},
	{
		path: 'survey',
		canActivate: [RedirectGuard],
		component: RedirectGuard,
		data: {
			externalUrl: 'https://tally.so/r/waeKaW'
		}
	},
    {
        path: '',
        loadChildren: () => import('./public/user-profile/user-profile.module').then(m => m.UserProfileModule)
    }
];


const profileRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./public/profile/profile.module').then(m => m.ProfileModule)
    }
]


const redirectRoutes: Routes = [
    {
        path: '',
        loadChildren: () => new Promise(() => {

            const hostname = window.location.hostname.replace("https://", "").replace("http://", "");

			const suffixes = ['wow.mt', 'card.mt', 'teams.mt', 'wowkard.com', 'artists.mt'];
			const result = suffixes.map(suffix => ({
					suffix,
					index: hostname.indexOf(`.${suffix}`)
				})).find(item => item.index >= 0) || { suffix: null, index: -1 };
				
			const prefix = hostname.substring(0, result.index);

            if(prefix) {
                const pathname = window?.location?.pathname?.replace("/", "");
                const pagename = !pathname
                    ? prefix
                    : `${pathname}@${prefix}`;

                console.log("pathname: " + pathname);
                console.log("pagename: " + pagename);

                window.location.href = `https://${result.suffix}/${pagename}`;
            }
			else {
				window.location.href = `https://${result.suffix}`;
			}
			
        })
    },
]

const isSubdomainForRedirection: boolean =
    (window.location.hostname.includes('.wow.mt') || window.location.hostname.includes('.card.mt') || window.location.hostname.includes('.teams.mt'))
    && !window.location.hostname.includes('beta.wow.mt')
    && !window.location.hostname.includes('blog.wow.mt')
    && !window.location.hostname.includes('www.wow.mt');

const isNotProfile: boolean = new RegExp(routes.filter(x => !!x.path).map(x => x.path).join('|')).test(window.location.href);


@NgModule({
    imports: [
        RouterModule.forRoot(
            isSubdomainForRedirection
                ? redirectRoutes
                : routes
        )

        // RouterModule.forRoot(
        //     !isNotProfile
        //     ? profileRoutes
        //     : (isSubdomainForRedirection ? redirectRoutes : routes)
        // )

        //RouterModule.forRoot(routes)
        //RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { Injectable } from "@angular/core";
import { Logger } from "src/app/core/logger/logger";
import { SwitchStore } from "../store/switch.store";

@Injectable()
export class SwitchProfileResolver  {

    constructor(public store: SwitchStore) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const profileId = route.paramMap.get('profileId') ?? this.store.get.currentProfileId;
        const pagename = route.paramMap.get('pagename') ?? this.store.get.currentPagename;

        Logger.logServiceMessage(`Fetching profile for pagename: ${pagename} and profileID: ${profileId}`, this);

        if (!profileId || !pagename) {
            return false;
        }

		return this.store.getProfileByPagename(pagename);

        // CHECK COMBINELATEST: https://medium.com/@sebastianvega_31695/creating-a-route-resolver-for-multiple-requests-angular-4-471178efba29
        // let profileCall = this.switchStore.getProfileByPagename(pagename);
        // let profileDesignCall = this.dashboardStore.getProfileDesign(pagename);
        // return forkJoin([profileCall, profileDesignCall])

        //return this.dashboardStore.getProfileDesign(pagename);
    }

}
import * as R from 'ramda';
import {BehaviorSubject, Subject} from 'rxjs';
import {Logger} from '../logger/logger';


/**
 * This class provides the ability to extend native Javascript Proxy class.
 */
class ExtendableProxy<T> {
    readonly onModelUpdate = new Subject<{
        oldValue: T[keyof T];
        newValue: T[keyof T];
        prop: keyof T | 'model';
    }>();

    protected readonly observables: Map<keyof T,
        BehaviorSubject<T[keyof T] | undefined>> = new Map();

    protected model: Partial<T>;

    constructor(
        handler: {
            get: (modelInstance: any, prop: any, proxy: any) => any;
            set: (modelInstance: any, prop: any, data: any) => boolean;
        },
        initialData: Partial<T> = {}
    ) {
        this.model = R.clone(initialData);
        return new Proxy(this, handler);
    }
}

/**
 * Should be extended by concrete class in order to be able to inject concrete classes
 * plus to provide concrete model Interface (T).
 *
 * example:
 *
 *  export interface ITestModel {
 *    name: string;
 *    population: number;
 *    deep: {
 *      deeper: {
 *        city: string
 *      }
 *    };
 *  }
 *
 *
 *   export class LocalStorageModel extends AbstractReactiveModel<ITestModel> {
 *
 *    constructor() {
 *      super();
 *      this.onModelUpdate.subscribe((updateModelVO) => {
 *        this.saveToStorage(updateModelVO.newValue);
 *      });
 *    }
 *
 *    private saveToStorage(model: ITestModel) {
 *      console.log('Save to local storage!!!', model);
 *    }
 *  }
 *
 *
 */
export abstract class AbstractReactiveModel<T extends object> extends ExtendableProxy<T> {

    constructor(initialData?: T) {
        const handler = {
            get: function (
                modelInstance: T & AbstractReactiveModel<T>,
                prop: keyof T & keyof AbstractReactiveModel<T>,
                proxy: T
            ) {
                if ((prop as string) in modelInstance) {
                    // we just ignore those properties, because we know those are not part of the interface T
                    // as example you can use myModel.set.x or myModel.observe.x, in this case we ignore the access to 'observe' or 'set'
                    if (prop === 'observe' || prop === 'set') {
                        return proxy;
                    }

                    // return property value of interface T
                    return modelInstance[prop];
                }

                // return observer for the property;
                return modelInstance.getObservable(modelInstance, prop);
            },
            set: function (
                modelInstance: T & { model: T; readModel: Readonly<Partial<T>> },
                prop: keyof T,
                data: T[keyof T]
            ) {
                let oldValue: T[keyof T] | Readonly<T> | undefined;
                let found = false;

                if (prop in modelInstance && prop === 'model') {
                    found = true;
                    oldValue = modelInstance.readModel as Readonly<T>;
                    (modelInstance as any)[prop] = data;
                } else if (prop in modelInstance.model) {
                    found = true;
                    oldValue = modelInstance.model[prop];
                    modelInstance.model[prop] = R.clone(data); // better not to allow direct writing to avoid possible problems with objects and arrays
                    (modelInstance as any).getObservable(modelInstance, prop).next(data);
                }

                if (found) {
                    Logger.logModelMessage(
                        [
                            `Model update '${String(prop)}' `,
                            {
                                OLD_VALUE: oldValue,
                                NEW_VALUE: data,
                            },
                        ],
                        modelInstance
                    );

                    if ((modelInstance as any).onModelUpdate.observed) {
                        const vo = {oldValue, newValue: R.clone(data), prop};
                        (modelInstance as any).onModelUpdate.next(vo);
                    }
                }

                if (!found) {
                    (modelInstance as any)[prop] = data;
                    Logger.logModelMessage(
                        [`Added new class property: '${modelInstance?.constructor?.name}.${String(prop)}:<${(data as any)?.constructor?.name}>' `, data], modelInstance, 'warn');
                    return true;
                }

                return found;
            }
        };

        super(handler, initialData);

        Logger
            .logServiceMessage([`Created`], this);
    }

    /**
     * Get observer for individual Interface property
     */
    get observe(): { [P in keyof T]: BehaviorSubject<T[P]> } {
        return <any>this;
    }

    /**
     * Set individual property inside the model.
     */
    get set(): T {
        return <any>this;
    }

    get get(): T {
        return this.model as Readonly<T>;
    }

    /**
     * Get clone of current model state.
     */
    getModel(): T {
        const returnModel = R.clone(this.model as T);

        // eslint-disable-next-line @typescript-eslint/ban-types
        R.keys(returnModel).forEach((key) => {
            returnModel[key as keyof T] = this.cloneArrayOrMap(
                returnModel[key as keyof T]
            );
        });

        return returnModel;
    }

    /**
     * Pass entire object to be merged with current model. All duplicated properties will be overwritten.
     */
    updateModel(state: Partial<T>) {
        const observables = this.observables;

        const newModel = R.mergeDeepRight(this.model, R.clone(state)) as Partial<T>;
        const cloneFunction = this.cloneArrayOrMap;

        // we need to make sure the instances passed to updateModel of Array, or Map does not leak into actual model
        // this could allow for silent model updating and we don't want to allow this.
        R.keys(state).forEach((key) => {
            const receivedValue = state[key];
            const cloneValue = newModel[key];

            // this might happen due to the fact that for now when we read the value from array
            // via observe.XXXX or model.getModel() we are not cloning Array, Map, Object, Set (reference types)
            // this means that is very possible same instance to have been edited and then returned into the model
            if (receivedValue === cloneValue) {
                newModel[key] = cloneFunction(cloneValue);
            }
        });

        R.keys(state).forEach((key) => {
            let observable = observables.get(key);

            // create the observable for each property in the state
            if (!observable) {
                observable = this.getObservable(this, key);
            }

            Logger.logModelMessage(
                [`Set property '${String(key)}' value:`, newModel[key]],
                this
            );
            observable.next(newModel[key]);
        });

        this.model = newModel;
    }

    private getObservable(
        _this: this,
        key: keyof T
    ): BehaviorSubject<T[keyof T] | undefined> {
        let subject = _this.observables.get(key);

        if (!subject) {
            subject = new BehaviorSubject<T[keyof T] | undefined>(_this.model[key]);
            _this.observables.set(key, subject);
        }

        return subject;
    }

    private cloneArrayOrMap(value: any | Array<unknown> | Map<unknown, unknown>) {
        if (value instanceof Map) {
            return new Map(value);
        }

        if (value instanceof Array) {
            return [...value];
        }

        return value;
    }
}

/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Tiers = 'Basic' | 'Premium' | 'Influencer' | 'Team' | 'TeamMember' | 'Exhibitor' | 'ConferencePage';

export const Tiers = {
    Basic: 'Basic' as Tiers,
    Premium: 'Premium' as Tiers,
    Influencer: 'Influencer' as Tiers,
    Team: 'Team' as Tiers,
    TeamMember: 'TeamMember' as Tiers,
    Exhibitor: 'Exhibitor' as Tiers,
    ConferencePage: 'ConferencePage' as Tiers
};
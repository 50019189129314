import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Pages } from '../helpers/pages';
import { TokenStorageService } from './../services/token-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private readonly token: TokenStorageService, private readonly router: Router) { }

    canActivate(): boolean {
        if (!this.token.getToken()) {
            this.router.navigateByUrl(Pages.login);
        }

        return true;
    }

}

/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ElementTypes = 'Link' | 'Sponsor' | 'Deal' | 'Event' | 'Social' | 'Profile' | 'Agenda' | 'Conference' | 'Awards' | 'Project' | 'Contest' | 'Competition' | 'Experience' | 'Music' | 'Education' | 'Address' | 'Section';

export const ElementTypes = {
    Link: 'Link' as ElementTypes,
    Sponsor: 'Sponsor' as ElementTypes,
    Deal: 'Deal' as ElementTypes,
    Event: 'Event' as ElementTypes,
    Social: 'Social' as ElementTypes,
    Profile: 'Profile' as ElementTypes,
    Agenda: 'Agenda' as ElementTypes,
    Conference: 'Conference' as ElementTypes,
    Awards: 'Awards' as ElementTypes,
    Project: 'Project' as ElementTypes,
    Contest: 'Contest' as ElementTypes,
    Competition: 'Competition' as ElementTypes,
    Experience: 'Experience' as ElementTypes,
    Music: 'Music' as ElementTypes,
    Education: 'Education' as ElementTypes,
    Address: 'Address' as ElementTypes,
    Section: 'Section' as ElementTypes
};
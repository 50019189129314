import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {Injectable} from '@angular/core';
import {Logger} from '../../../core/logger/logger';
import {Observable} from 'rxjs';
import { SwitchStore } from '../store/switch.store';

@Injectable()
export class SwitchProfileList2Resolver  {

    constructor(public store: SwitchStore) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        Logger.logServiceMessage(`Fetched. Loading Profile Lists 2`, this);

        return this.store.fetchProfileLists();
    }
}

<ion-split-pane contentId="switch-content" when="xl" disabled="false">

    <!-- https://github.com/ionic-team/ionic-framework/issues/18683 -->
    <div class="ion-menu">
        <ion-content>
            <wui-sidebar-empty></wui-sidebar-empty>
        </ion-content>
    </div>


    <ion-router-outlet id="switch-content">
    </ion-router-outlet>

</ion-split-pane>
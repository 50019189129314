/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Categories = 'Individual' | 'Group' | 'Business' | 'Organisation' | 'Startup' | 'ConferencePage';

export const Categories = {
    Individual: 'Individual' as Categories,
    Group: 'Group' as Categories,
    Business: 'Business' as Categories,
    Organisation: 'Organisation' as Categories,
    Startup: 'Startup' as Categories,
    ConferencePage: 'ConferencePage' as Categories
};
import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { filter } from 'rxjs/operators';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    // https://medium.com/beingcoders/how-to-use-google-analytics-in-angular-9a6131979819

    title: string = 'The Digital Business Card for Performers and Creatives';
    loadingDataImg: boolean = false;

    constructor(private router: Router) {
        router.events.subscribe(event => {
            this.navigationInterceptor(event);
        });

        /** START : Code to Track Page View using gtag.js */
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            gtag('event', 'page_view', {
                page_path: event.urlAfterRedirects
            })
        })
        /** END : Code to Track Page View  using gtag.js */
    }

    ngOnInit() {
        window.scrollTo(0, 0);

        ReactiveFormConfig.set({
            // "internationalization": {
            //     "dateFormat": "dmy",
            //     "seperator": "/"
            // },
            "validationMessage": {
                "email": "Email entered is not valid",
                "minLength": "Minimum length should be {{1}} letter",
                "maxLength": "Allowed maximum length is {{1}} letter",
                "password": "Please include a number, a special character, a lowercase & an uppercase letter",
                "required": "Please enter a value",
                "url": "Please enter a valid Url",

                "alpha": "Only alphabelts are allowed.",
                "alphaNumeric": "Only alphabet and numbers are allowed.",
                "compare": "inputs are not matched.",
                "contains": "value is not contains in the input",
                "creditcard": "Creditcard number is not correct",
                "digit": "Only digits are allowed",
                "greaterThanEqualTo": "please enter greater than or equal to the joining age",
                "greaterThan": "please enter greater than to the joining age",
                "hexColor": "please enter hex code",
                "json": "please enter valid json",
                "lessThanEqualTo": "please enter less than or equal to the current experience",
                "lessThan": "please enter less than or equal to the current experience",
                "lowerCase": "Only lowercase letters are allowed",
                "maxNumber": "Enter value less than equal to 3",
                "minNumber": "Enter value greater than equal to 1",
                "pattern": "please enter valid zipcode",
                "range": "please enter age between 18 to 60",
                "time": "Only time format is allowed",
                "upperCase": "Only uppercase is allowed",
                "zipCode": "enter valid zip code",
            }
        });
    }


    // collect that title data properties from all child routes
    // there might be a better way but this worked for me
    getTitle(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event: any): void {

        //Triggered When the navigation starts
        if (event instanceof NavigationStart) {
            this.loadingDataImg = true;
        }
        //Triggered When the navigation ends
        if (event instanceof NavigationEnd) {
            this.loadingDataImg = false;
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.loadingDataImg = false;
        }
        //Triggered When the error occurrs while navigation
        if (event instanceof NavigationError) {
            this.loadingDataImg = false;
        }
    }
}

import { Tiers } from "src/codegen/model/models";

export enum APP_FEATURES {
    // Team and Participants will be grouped
	TEAM = 'TEAM',
	PARTICIPANTS = 'PARTICIPANTS',

    ANALYTICS = 'ANALYTICS',
    BIO = 'BIO',
    BRANDING = 'BRANDING',
    SOCIALS = 'SOCIALS',
    CONTENT = 'CONTENT',
    CONTENT_AGENDA = 'CONTENT_AGENDA',
    CARDS = 'CARDS',
    CONTACTS = 'CONTACTS',
    CONFERENCE_PAGES = 'CONFERENCE_PAGES',
    PERMISSIONS = 'PERMISSIONS',
    INTEGRATIONS = 'INTEGRATIONS',
    PREVIEW = 'PREVIEW'
};

export enum APP_PAGES {
	INDIVIDUAL_PAGE = 'INDIVIDUAL_PAGE',
	TEAM_PAGE = 'TEAM_PAGE',
	CONFERENCE_PAGE = 'CONFERENCE_PAGE'
};



// Make feature flags and tiers intellisens-ible
type FeatureTypes = keyof typeof APP_FEATURES;
type TierTypes = keyof typeof Tiers;
type FeatureFlags = {
    [tier in TierTypes]?: {
        [feature in FeatureTypes]?: boolean;
    };
};

// Set features based on the Tier
export const FEATURE_FLAGS: FeatureFlags = {
    Basic: {
		BIO: true,
		BRANDING: true,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		PREVIEW: true,
		INTEGRATIONS: false,
    },
	Premium: {
        ANALYTICS: true,
		BIO: true,
		BRANDING: true,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		CONTACTS: true,
		PERMISSIONS: true,
		PREVIEW: true,
		INTEGRATIONS: false,
	},
	Influencer: {
        ANALYTICS: true,
		BIO: true,
		BRANDING: true,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		CONTACTS: true,
		PERMISSIONS: false,
		INTEGRATIONS: true,
		PREVIEW: true,
	},
    Team: {
        TEAM: true,
        ANALYTICS: true,
		BIO: true,
		BRANDING: true,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		CONTACTS: true,
		PERMISSIONS: true,
		INTEGRATIONS: true,
		CONFERENCE_PAGES: true
    },
    TeamMember: {
        ANALYTICS: true,
		BIO: true,
		BRANDING: false,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		CONTACTS: true,
		PERMISSIONS: false,
		PREVIEW: true,
    },
    Exhibitor: {
        ANALYTICS: true,
		BIO: true,
		BRANDING: false,
		SOCIALS: true,
		CONTENT: true,
		CARDS: true,
		CONTACTS: true,
		PERMISSIONS: false,
		PREVIEW: true,
    },
    ConferencePage: {
        ANALYTICS: true,
		BIO: true,
		BRANDING: true,
		SOCIALS: true,
		CONTENT_AGENDA: true,
		CONTENT: true,
		CARDS: false,
		CONTACTS: true,
		PERMISSIONS: true,
		PARTICIPANTS: true,
		CONFERENCE_PAGES: false
    }
};

/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetProfileIdByUserAccessResponse } from '../model/getProfileIdByUserAccessResponse';
import { IndividualProfileAboutUpdateModel } from '../model/individualProfileAboutUpdateModel';
import { ProfileAboutResponseBody } from '../model/profileAboutResponseBody';
import { ProfileAboutTextUpdateRequest } from '../model/profileAboutTextUpdateRequest';
import { ProfileCreateChildRequest } from '../model/profileCreateChildRequest';
import { ProfileCreateChildResponse } from '../model/profileCreateChildResponse';
import { ProfileCreateRequest } from '../model/profileCreateRequest';
import { ProfileCreateResponse } from '../model/profileCreateResponse';
import { ProfileDesignResponseBody } from '../model/profileDesignResponseBody';
import { ProfileDesignUpdateRequest } from '../model/profileDesignUpdateRequest';
import { ProfilePublicResponse } from '../model/profilePublicResponse';
import { ProfileResponse } from '../model/profileResponse';
import { ProfileResponseLists } from '../model/profileResponseLists';
import { ProfileTagListResponse } from '../model/profileTagListResponse';
import { ProfileTagResponse } from '../model/profileTagResponse';
import { ProfileTagTitleAddRequest } from '../model/profileTagTitleAddRequest';
import { ProfileUpdateRequest } from '../model/profileUpdateRequest';
import { ServiceStatusResponse } from '../model/serviceStatusResponse';
import { SetProfileCustomThemeDto } from '../model/setProfileCustomThemeDto';
import { Statuses } from '../model/statuses';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProfileAdminService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param profileId 
     * @param tagId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTalent(profileId: string, tagId: number, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public addTalent(profileId: string, tagId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public addTalent(profileId: string, tagId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public addTalent(profileId: string, tagId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling addTalent.');
        }

        if (tagId === null || tagId === undefined) {
            throw new Error('Required parameter tagId was null or undefined when calling addTalent.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ServiceStatusResponse>('post',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/tag/${encodeURIComponent(String(tagId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTalentTitle(profileId: string, body?: ProfileTagTitleAddRequest, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public addTalentTitle(profileId: string, body?: ProfileTagTitleAddRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public addTalentTitle(profileId: string, body?: ProfileTagTitleAddRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public addTalentTitle(profileId: string, body?: ProfileTagTitleAddRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling addTalentTitle.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ServiceStatusResponse>('post',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/tags/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createChildProfile(body?: ProfileCreateChildRequest, observe?: 'body', reportProgress?: boolean): Observable<ProfileCreateChildResponse>;
    public createChildProfile(body?: ProfileCreateChildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileCreateChildResponse>>;
    public createChildProfile(body?: ProfileCreateChildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileCreateChildResponse>>;
    public createChildProfile(body?: ProfileCreateChildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ProfileCreateChildResponse>('post',`${this.basePath}/api/admin/profiles/create/child`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProfile(body?: ProfileCreateRequest, observe?: 'body', reportProgress?: boolean): Observable<ProfileCreateResponse>;
    public createProfile(body?: ProfileCreateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileCreateResponse>>;
    public createProfile(body?: ProfileCreateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileCreateResponse>>;
    public createProfile(body?: ProfileCreateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ProfileCreateResponse>('post',`${this.basePath}/api/admin/profiles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAbout(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileAboutResponseBody>;
    public getAbout(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileAboutResponseBody>>;
    public getAbout(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileAboutResponseBody>>;
    public getAbout(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getAbout.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileAboutResponseBody>('get',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/about`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileIdAsString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByProfileId(profileIdAsString: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileResponse>;
    public getByProfileId(profileIdAsString: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponse>>;
    public getByProfileId(profileIdAsString: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponse>>;
    public getByProfileId(profileIdAsString: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileIdAsString === null || profileIdAsString === undefined) {
            throw new Error('Required parameter profileIdAsString was null or undefined when calling getByProfileId.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileResponse>('get',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileIdAsString))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getByProfilePagename(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileResponse>;
    public getByProfilePagename(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponse>>;
    public getByProfilePagename(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponse>>;
    public getByProfilePagename(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getByProfilePagename.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileResponse>('get',`${this.basePath}/api/admin/profiles/pagename/${encodeURIComponent(String(pagename))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDesign(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileDesignResponseBody>;
    public getDesign(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileDesignResponseBody>>;
    public getDesign(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileDesignResponseBody>>;
    public getDesign(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getDesign.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileDesignResponseBody>('get',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/design`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIdByUserAccess(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<GetProfileIdByUserAccessResponse>;
    public getIdByUserAccess(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetProfileIdByUserAccessResponse>>;
    public getIdByUserAccess(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetProfileIdByUserAccessResponse>>;
    public getIdByUserAccess(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getIdByUserAccess.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetProfileIdByUserAccessResponse>('get',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/id`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfile4CompleteById(profileId: string, observe?: 'body', reportProgress?: boolean): Observable<ProfilePublicResponse>;
    public getProfile4CompleteById(profileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfilePublicResponse>>;
    public getProfile4CompleteById(profileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfilePublicResponse>>;
    public getProfile4CompleteById(profileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getProfile4CompleteById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfilePublicResponse>('get',`${this.basePath}/api/admin/profiles/complete/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileLists(observe?: 'body', reportProgress?: boolean): Observable<ProfileResponseLists>;
    public getProfileLists(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponseLists>>;
    public getProfileLists(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponseLists>>;
    public getProfileLists(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileResponseLists>('get',`${this.basePath}/api/admin/profiles/lists`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfileTalents(profileId: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileTagListResponse>;
    public getProfileTalents(profileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileTagListResponse>>;
    public getProfileTalents(profileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileTagListResponse>>;
    public getProfileTalents(profileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getProfileTalents.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileTagListResponse>('get',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/tags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public isPagenameUnique(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public isPagenameUnique(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public isPagenameUnique(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public isPagenameUnique(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling isPagenameUnique.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/admin/profiles/unique/${encodeURIComponent(String(pagename))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param profileTagId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeProfileTalent(profileId: string, profileTagId: number, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public removeProfileTalent(profileId: string, profileTagId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public removeProfileTalent(profileId: string, profileTagId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public removeProfileTalent(profileId: string, profileTagId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling removeProfileTalent.');
        }

        if (profileTagId === null || profileTagId === undefined) {
            throw new Error('Required parameter profileTagId was null or undefined when calling removeProfileTalent.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ServiceStatusResponse>('delete',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/tag/${encodeURIComponent(String(profileTagId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param cardId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCardToProfile(profileId: string, cardId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setCardToProfile(profileId: string, cardId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setCardToProfile(profileId: string, cardId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setCardToProfile(profileId: string, cardId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling setCardToProfile.');
        }

        if (cardId === null || cardId === undefined) {
            throw new Error('Required parameter cardId was null or undefined when calling setCardToProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/card/${encodeURIComponent(String(cardId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setCustomTheme(profileId: string, body?: SetProfileCustomThemeDto, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public setCustomTheme(profileId: string, body?: SetProfileCustomThemeDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public setCustomTheme(profileId: string, body?: SetProfileCustomThemeDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public setCustomTheme(profileId: string, body?: SetProfileCustomThemeDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling setCustomTheme.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ServiceStatusResponse>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/theme`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param themeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setTheme(profileId: string, themeId: number, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public setTheme(profileId: string, themeId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public setTheme(profileId: string, themeId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public setTheme(profileId: string, themeId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling setTheme.');
        }

        if (themeId === null || themeId === undefined) {
            throw new Error('Required parameter themeId was null or undefined when calling setTheme.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ServiceStatusResponse>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/theme/${encodeURIComponent(String(themeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sortProfileTalents(profileId: string, body?: Array<ProfileTagResponse>, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public sortProfileTalents(profileId: string, body?: Array<ProfileTagResponse>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public sortProfileTalents(profileId: string, body?: Array<ProfileTagResponse>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public sortProfileTalents(profileId: string, body?: Array<ProfileTagResponse>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling sortProfileTalents.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ServiceStatusResponse>('post',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/tags/sort`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sortProfileTalentsByPagename(pagename: string, body?: Array<ProfileTagResponse>, observe?: 'body', reportProgress?: boolean): Observable<ServiceStatusResponse>;
    public sortProfileTalentsByPagename(pagename: string, body?: Array<ProfileTagResponse>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceStatusResponse>>;
    public sortProfileTalentsByPagename(pagename: string, body?: Array<ProfileTagResponse>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceStatusResponse>>;
    public sortProfileTalentsByPagename(pagename: string, body?: Array<ProfileTagResponse>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling sortProfileTalentsByPagename.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ServiceStatusResponse>('post',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/tags/sort-pagename`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAbout(pagename: string, body?: IndividualProfileAboutUpdateModel, observe?: 'body', reportProgress?: boolean): Observable<ProfileResponse>;
    public updateAbout(pagename: string, body?: IndividualProfileAboutUpdateModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponse>>;
    public updateAbout(pagename: string, body?: IndividualProfileAboutUpdateModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponse>>;
    public updateAbout(pagename: string, body?: IndividualProfileAboutUpdateModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling updateAbout.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ProfileResponse>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/about`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param priority 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAboutPriority(pagename: string, priority: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAboutPriority(pagename: string, priority: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAboutPriority(pagename: string, priority: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAboutPriority(pagename: string, priority: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling updateAboutPriority.');
        }

        if (priority === null || priority === undefined) {
            throw new Error('Required parameter priority was null or undefined when calling updateAboutPriority.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/about/${encodeURIComponent(String(priority))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAboutText(pagename: string, body?: ProfileAboutTextUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAboutText(pagename: string, body?: ProfileAboutTextUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAboutText(pagename: string, body?: ProfileAboutTextUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAboutText(pagename: string, body?: ProfileAboutTextUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling updateAboutText.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/about/text`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDesign(pagename: string, body?: ProfileDesignUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDesign(pagename: string, body?: ProfileDesignUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDesign(pagename: string, body?: ProfileDesignUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDesign(pagename: string, body?: ProfileDesignUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling updateDesign.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(pagename))}/design`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfile(profileId: string, body?: ProfileUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateProfile(profileId: string, body?: ProfileUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateProfile(profileId: string, body?: ProfileUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateProfile(profileId: string, body?: ProfileUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling updateProfile.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param profileStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProfileStatus(profileId: string, profileStatus: Statuses, observe?: 'body', reportProgress?: boolean): Observable<ProfileResponse>;
    public updateProfileStatus(profileId: string, profileStatus: Statuses, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileResponse>>;
    public updateProfileStatus(profileId: string, profileStatus: Statuses, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileResponse>>;
    public updateProfileStatus(profileId: string, profileStatus: Statuses, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling updateProfileStatus.');
        }

        if (profileStatus === null || profileStatus === undefined) {
            throw new Error('Required parameter profileStatus was null or undefined when calling updateProfileStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileResponse>('put',`${this.basePath}/api/admin/profiles/${encodeURIComponent(String(profileId))}/update/status/${encodeURIComponent(String(profileStatus))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

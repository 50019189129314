/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ProfileLinkTypes = 'Follower' | 'TeamMember' | 'Subscriber' | 'Speaker' | 'Sponsor' | 'Exhibitor' | 'Participant';

export const ProfileLinkTypes = {
    Follower: 'Follower' as ProfileLinkTypes,
    TeamMember: 'TeamMember' as ProfileLinkTypes,
    Subscriber: 'Subscriber' as ProfileLinkTypes,
    Speaker: 'Speaker' as ProfileLinkTypes,
    Sponsor: 'Sponsor' as ProfileLinkTypes,
    Exhibitor: 'Exhibitor' as ProfileLinkTypes,
    Participant: 'Participant' as ProfileLinkTypes
};
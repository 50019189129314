import { IonicModule, MenuController, Platform } from '@ionic/angular';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Pages } from 'src/app/core/helpers/pages';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		IonicModule,
		FontAwesomeModule
	],
    selector:  'wui-sidebar-empty',
    templateUrl: './sidebar-empty.component.html',
    styleUrls: [ './sidebar-empty.component.scss' ]
})
export class WuiSidebarEmptyComponent {

    constructor(
		private readonly menuCtrl: MenuController,
		private readonly router: Router,
		private readonly token: TokenStorageService,
		public readonly  platform: Platform) {
	}

    onChangePassword() {
        this.router.navigateByUrl("/auth/change?returnUrl=/switch");
    }

    onLogout() {
        this.token.signOut();
        this.router.navigateByUrl(Pages.login);
    }

    onToggleMenu() {
        this.menuCtrl.toggle();
    }

    isMobile() {
        return this.platform.is("mobile");
    }

}
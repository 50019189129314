<div class="sidemenu-header__container">
    <img src="./assets/images/logo/logo-only-black.svg" alt="Wow Logo" class="sidemenu-header__logo">
</div>

<ion-list class="bottom-list">

    <ion-item routerDirection="root"
		lines="none"
		(click)="onChangePassword()"
		>
        <fa-icon [icon]="['fal', 'lock']"></fa-icon>
        <ion-label>Change Password</ion-label>
    </ion-item>

    <ion-item routerDirection="root"
		lines="none"
		(click)="onLogout()"
		>
        <fa-icon [icon]="['fal', 'power-off']"></fa-icon>
        <ion-label>Logout</ion-label>
    </ion-item>

    <ion-item *ngIf="isMobile()"
		routerDirection="root"
		lines="none"
		(click)="onToggleMenu()"
		>
        <fa-icon [icon]="['fas', 'times']"></fa-icon>
        <ion-label>Close</ion-label>
    </ion-item>

</ion-list>
/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Element } from '../model/element';
import { ProfileElementAdminResponseBody } from '../model/profileElementAdminResponseBody';
import { ProfileSocialAddRequest } from '../model/profileSocialAddRequest';
import { ProfileSocialAdminResponse } from '../model/profileSocialAdminResponse';
import { ProfileSocialUpdateManyRequest } from '../model/profileSocialUpdateManyRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElementsAdminService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(profileId: string, body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public create(profileId: string, body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public create(profileId: string, body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public create(profileId: string, body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling create.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(profileId))}/create`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSocial(profileId: string, body?: ProfileSocialAddRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createSocial(profileId: string, body?: ProfileSocialAddRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createSocial(profileId: string, body?: ProfileSocialAddRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createSocial(profileId: string, body?: ProfileSocialAddRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling createSocial.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(profileId))}/create/social`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param elementId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteByPagename(pagename: string, elementId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteByPagename(pagename: string, elementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteByPagename(pagename: string, elementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteByPagename(pagename: string, elementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling deleteByPagename.');
        }

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling deleteByPagename.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/${encodeURIComponent(String(elementId))}/delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param elementCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEntriesByCategory(pagename: string, elementCategory: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileElementAdminResponseBody>;
    public getEntriesByCategory(pagename: string, elementCategory: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileElementAdminResponseBody>>;
    public getEntriesByCategory(pagename: string, elementCategory: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileElementAdminResponseBody>>;
    public getEntriesByCategory(pagename: string, elementCategory: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getEntriesByCategory.');
        }

        if (elementCategory === null || elementCategory === undefined) {
            throw new Error('Required parameter elementCategory was null or undefined when calling getEntriesByCategory.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileElementAdminResponseBody>('get',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/${encodeURIComponent(String(elementCategory))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSocialEntries(pagename: string, observe?: 'body', reportProgress?: boolean): Observable<ProfileElementAdminResponseBody>;
    public getSocialEntries(pagename: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProfileElementAdminResponseBody>>;
    public getSocialEntries(pagename: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProfileElementAdminResponseBody>>;
    public getSocialEntries(pagename: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling getSocialEntries.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProfileElementAdminResponseBody>('get',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/social`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sortElements(pagename: string, body?: Array<Element>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sortElements(pagename: string, body?: Array<Element>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sortElements(pagename: string, body?: Array<Element>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sortElements(pagename: string, body?: Array<Element>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling sortElements.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/sort`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sortHomepageElements(pagename: string, body?: Array<Element>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sortHomepageElements(pagename: string, body?: Array<Element>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sortHomepageElements(pagename: string, body?: Array<Element>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sortHomepageElements(pagename: string, body?: Array<Element>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling sortHomepageElements.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/sort/frontpage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sortSocials(pagename: string, body?: Array<ProfileSocialAdminResponse>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sortSocials(pagename: string, body?: Array<ProfileSocialAdminResponse>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sortSocials(pagename: string, body?: Array<ProfileSocialAdminResponse>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sortSocials(pagename: string, body?: Array<ProfileSocialAdminResponse>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling sortSocials.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/sort/socials`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSocials(profileId: string, body?: ProfileSocialUpdateManyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateSocials(profileId: string, body?: ProfileSocialUpdateManyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateSocials(profileId: string, body?: ProfileSocialUpdateManyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateSocials(profileId: string, body?: ProfileSocialUpdateManyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling updateSocials.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(profileId))}/update/socials`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param pagename 
     * @param elementId 
     * @param elementStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatus(pagename: string, elementId: number, elementStatus: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateStatus(pagename: string, elementId: number, elementStatus: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateStatus(pagename: string, elementId: number, elementStatus: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateStatus(pagename: string, elementId: number, elementStatus: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pagename === null || pagename === undefined) {
            throw new Error('Required parameter pagename was null or undefined when calling updateStatus.');
        }

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling updateStatus.');
        }

        if (elementStatus === null || elementStatus === undefined) {
            throw new Error('Required parameter elementStatus was null or undefined when calling updateStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/admin/elements/${encodeURIComponent(String(pagename))}/${encodeURIComponent(String(elementId))}/status/${encodeURIComponent(String(elementStatus))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

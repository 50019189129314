/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ElementEventStatusResponse } from '../model/elementEventStatusResponse';
import { ElementListStatusResponse } from '../model/elementListStatusResponse';
import { ElementStatusResponse } from '../model/elementStatusResponse';
import { UpdateElement } from '../model/updateElement';
import { UpdateElementEvent } from '../model/updateElementEvent';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ElementPortalService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param parentProfileId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createElement(parentProfileId: string, body?: UpdateElement, observe?: 'body', reportProgress?: boolean): Observable<ElementStatusResponse>;
    public createElement(parentProfileId: string, body?: UpdateElement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementStatusResponse>>;
    public createElement(parentProfileId: string, body?: UpdateElement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementStatusResponse>>;
    public createElement(parentProfileId: string, body?: UpdateElement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parentProfileId === null || parentProfileId === undefined) {
            throw new Error('Required parameter parentProfileId was null or undefined when calling createElement.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ElementStatusResponse>('post',`${this.basePath}/api/portal/element/${encodeURIComponent(String(parentProfileId))}/element/create`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param elementId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrUpdateEvent(profileId: string, elementId: number, body?: UpdateElementEvent, observe?: 'body', reportProgress?: boolean): Observable<ElementStatusResponse>;
    public createOrUpdateEvent(profileId: string, elementId: number, body?: UpdateElementEvent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementStatusResponse>>;
    public createOrUpdateEvent(profileId: string, elementId: number, body?: UpdateElementEvent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementStatusResponse>>;
    public createOrUpdateEvent(profileId: string, elementId: number, body?: UpdateElementEvent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling createOrUpdateEvent.');
        }

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling createOrUpdateEvent.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ElementStatusResponse>('post',`${this.basePath}/api/portal/element/${encodeURIComponent(String(profileId))}/event/${encodeURIComponent(String(elementId))}/update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementAgendaList(profileId: string, observe?: 'body', reportProgress?: boolean): Observable<ElementListStatusResponse>;
    public getElementAgendaList(profileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementListStatusResponse>>;
    public getElementAgendaList(profileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementListStatusResponse>>;
    public getElementAgendaList(profileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getElementAgendaList.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElementListStatusResponse>('get',`${this.basePath}/api/portal/element/${encodeURIComponent(String(profileId))}/element/list/agenda`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param elementId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementEventById(profileId: string, elementId: number, observe?: 'body', reportProgress?: boolean): Observable<ElementEventStatusResponse>;
    public getElementEventById(profileId: string, elementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementEventStatusResponse>>;
    public getElementEventById(profileId: string, elementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementEventStatusResponse>>;
    public getElementEventById(profileId: string, elementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getElementEventById.');
        }

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling getElementEventById.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElementEventStatusResponse>('get',`${this.basePath}/api/portal/element/${encodeURIComponent(String(profileId))}/event/${encodeURIComponent(String(elementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param parentProfileId 
     * @param conferenceProfileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementEventByLinkedProfile(parentProfileId: string, conferenceProfileId: string, observe?: 'body', reportProgress?: boolean): Observable<ElementEventStatusResponse>;
    public getElementEventByLinkedProfile(parentProfileId: string, conferenceProfileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementEventStatusResponse>>;
    public getElementEventByLinkedProfile(parentProfileId: string, conferenceProfileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementEventStatusResponse>>;
    public getElementEventByLinkedProfile(parentProfileId: string, conferenceProfileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parentProfileId === null || parentProfileId === undefined) {
            throw new Error('Required parameter parentProfileId was null or undefined when calling getElementEventByLinkedProfile.');
        }

        if (conferenceProfileId === null || conferenceProfileId === undefined) {
            throw new Error('Required parameter conferenceProfileId was null or undefined when calling getElementEventByLinkedProfile.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElementEventStatusResponse>('get',`${this.basePath}/api/portal/element/${encodeURIComponent(String(parentProfileId))}/element/linked/${encodeURIComponent(String(conferenceProfileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getElementList(profileId: string, observe?: 'body', reportProgress?: boolean): Observable<ElementListStatusResponse>;
    public getElementList(profileId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElementListStatusResponse>>;
    public getElementList(profileId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElementListStatusResponse>>;
    public getElementList(profileId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling getElementList.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ElementListStatusResponse>('get',`${this.basePath}/api/portal/element/${encodeURIComponent(String(profileId))}/element/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param elementId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateElement(profileId: string, elementId: number, body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateElement(profileId: string, elementId: number, body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateElement(profileId: string, elementId: number, body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateElement(profileId: string, elementId: number, body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling updateElement.');
        }

        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling updateElement.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/portal/element/${encodeURIComponent(String(profileId))}/${encodeURIComponent(String(elementId))}/update`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

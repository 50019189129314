export const environment = {
    name: "STAGING ENV",
    production: false,

    apiCodeGenUrl: "https://api-beta.wow.mt",
    apiUrl: "https://api-beta.wow.mt/api",
    
	wowUrl: "https://beta.wow.mt/",
	profileUrl: "https://beta.wow.mt/{pagename}",
	teamUrl: "https://beta.wow.mt/{pagename}@{parentPagename}",

    blobUrl: "https://storewowmt.blob.core.windows.net/",

    //defaultProfilePoster: "profiles/a85239d2-222a-4c8b-9e1e-08d9a5fdff6b/wow-bg-default.jpg",
	recaptcha: {
		siteKey: "6Ley938mAAAAAEjvXuqsE8jejjzHyZYdOM4Qpb7I"
	}
};

import {IGlobalAngular} from './global';
import {isArray, isObject, isString} from './type-guards';

type LogLevelTypes = 'log' | 'warn' | 'error';
type LogMessageTypes = string | number | object | undefined | unknown;
type ClassInstanceType = { constructor: { name: string } };


// ICONS
export const bookEmoji = String.fromCodePoint(0x1f4d6);
export const folderEmoji = String.fromCodePoint(0X1F4C2);
export const pictureEmoji = String.fromCodePoint(0x1F3DE);
export const flowersEmoji = String.fromCodePoint(0x1F490);

export const arrowNextLineCurve = String.fromCodePoint(0x21AA);


export const unicornEmoji = String.fromCodePoint(0x1f984);
export const bullEmoji = String.fromCodePoint(0X1F9AC);
export const camelEmoji = String.fromCodePoint(0X1F42B);
export const lockEmoji = String.fromCodePoint(0X1F512);
export const theaterEmoji = String.fromCodePoint(0X1F3AD);
export const cakeEmoji = String.fromCodePoint(0X1F370);
export const plusEmoji = String.fromCodePoint(0X02795);
export const minusEmoji = String.fromCodePoint(0X02796);


const groupsSet = new Set<any>();

export const global = getGlobalObject();

let lastLogGroup: string | ClassInstanceType = 'unknown';

export class Logger {
    static logViewMessage(
        message: LogMessageTypes[] | string,
        className: string | ClassInstanceType = lastLogGroup,
        type: LogLevelTypes = 'log'
    ) {

        if (!canLog(type)) {
            return;
        }

        const isSameGroup = className === lastLogGroup;

        if (isObject(className)) {
            className = className.constructor.name;
        }

        if (!isArray(message)) {
            message = [message];
        }

        const placeholders = generatePlaceholders(message);
        message = reArrangeMessages(message);

        const color1 = 'green';
        const color2 = 'DarkGreen';

        let originName = getGroupName(className);
        if (isSameGroup) {
            originName = `${arrowNextLineCurve}`;
        }

        const icon = getViewIcon(originName);

        console[type](
            '%c' +
            `${icon} ${originName}%c::` +
            placeholders.number.join('') +
            placeholders.string.join('') +
            placeholders.object.join(''),
            'color: ' + color2 + '; font-weight: 600;',
            'color: ' + color1,
            ...message
        );
    }

    static logServiceMessage(
        message: LogMessageTypes[] | string,
        className: string | ClassInstanceType = lastLogGroup,
        type: LogLevelTypes = 'log'
    ) {

        if (!canLog(type)) {
            return;
        }

        if (!isArray(message)) {
            message = [message];
        }

        const isSameGroup = className === lastLogGroup;

        const placeholders = generatePlaceholders(message);
        message = reArrangeMessages(message);

        let originName = getGroupName(className);
        if (isSameGroup) {
            originName = `${arrowNextLineCurve}`;
        }

        const icon = getServiceIcon(originName);
        const color1 = '#1E90FF';
        const color2 = '#135CA3';
        console[type](
            `%c${icon} ${originName}%c::` +
            placeholders.number.join('') +
            placeholders.string.join('') +
            placeholders.object.join(''),
            'color: ' + color2 + '; font-weight: 600;',
            'color: ' + color1,
            ...message
        );
    }

    static logModelMessage(
        message: LogMessageTypes[] | string,
        className: string | ClassInstanceType = lastLogGroup,
        type: LogLevelTypes = 'log'
    ) {


        if (!canLog(type)) {
            return;
        }

        if (!isArray(message)) {
            message = [message];
        }

        const isSameGroup = className === lastLogGroup;

        const placeholders = generatePlaceholders(message);
        message = reArrangeMessages(message);

        let originName = getGroupName(className);
        if (isSameGroup) {
            originName = `${arrowNextLineCurve}`;
        }

        const color = 'SlateGray';
        console[type](
            `%c${bookEmoji} ${originName}%c::` +
            placeholders.number.join('') +
            placeholders.string.join('') +
            placeholders.object.join(''),
            'color: ' + color + '; font-weight: 600;',
            'color: ' + color,
            ...message
        );
    }

    static closeAllGroups() {

        if (!canLog()) {
            return;
        }

        const groupNames = Array.from(groupsSet).reverse();
        groupNames.forEach((group) => {
            console.groupEnd();
        });
        groupsSet.clear();
    }

    static closeLogGroup(groupID: string | ClassInstanceType) {

        if (!canLog()) {
            return;
        }

        if (groupsSet.has(groupID)) {
            const groupNames = Array.from(groupsSet);

            const filteredNames = groupNames.slice(
                groupNames.indexOf(groupID),
                groupNames.length
            );

            filteredNames.forEach((groupName) => {
                console.groupEnd();
            });

            lastLogGroup = '';
            groupsSet.delete(groupID);
            // Logger.logServiceMessage(['Group closed'], groupID, 'warn');
        } else {
            // Logger.logServiceMessage([`Missing group!`], groupID, 'warn');
        }

        lastLogGroup = Array.from(groupsSet).reverse()[0] || lastLogGroup;
    }

    static openNewGroup(name: string | ClassInstanceType) {

        if (!canLog() || name === lastLogGroup) {
            return;
        }

        updateCurrentGroup(name);
    }

    static getLastGroupName(): any {
        return Array.from(groupsSet).shift();
    }
}

function generatePlaceholders(
    message: (number | object | string | (undefined | unknown))[]
) {
    const placeholders: {
        string: string[];
        object: string[];
        number: string[];
    } = {
        string: [],
        object: [],
        number: [],
    };
    message.forEach((item) => {
        switch (typeof item) {
            case 'number': {
                placeholders.number[placeholders.number.length] = `%d `;
                break;
            }
            case 'object': {
                placeholders.object[placeholders.object.length] = `%o `;
                break;
            }
            case 'undefined': {
                placeholders.string[placeholders.string.length] = `undefined `;
                break;
            }
            case 'boolean':
            case 'string': {
                placeholders.string[placeholders.string.length] = `%s `;
                break;
            }
        }
    });
    return placeholders;
}

function reArrangeMessages(message: LogMessageTypes[]) {
    const strings: string[] = [];
    const numbers: number[] = [];
    const objects: (object | unknown)[] = [];

    message.map((item) => {
        switch (typeof item) {
            case 'number': {
                numbers.push(item);
                break;
            }
            case 'object': {
                objects.push(item);
                break;
            }
            case 'boolean': {
                strings.push(`'${item}'`);
                break;
            }
            case 'string': {
                strings.push(item);
                break;
            }
        }
    });
    return [...numbers, ...strings, ...objects];
}

function updateCurrentGroup(groupID: string | ClassInstanceType) {
    let groupName: string;

    if (isString(groupID)) {
        groupName = groupID;
    } else {
        groupName = groupID.constructor.name;
    }

    if (!groupsSet.has(groupID)) {
        groupsSet.add(groupID);
    } else {
        const groupNames = Array.from(groupsSet);
        groupNames.forEach(() => {
            console.groupEnd();
        });
        const filteredNames = groupNames.slice(0, groupNames.indexOf(groupID));
        filteredNames.forEach((tempGroupName) => {
            console.group(`${folderEmoji} ${getGroupName(tempGroupName)}`);
        });
    }

    console.group(`${folderEmoji} ${groupName}`);

    // Logger.logServiceMessage([`Current groups hierarchy: `, Array.from(groupsSet)], Logger.name)

    lastLogGroup = groupID;
}

function getGroupName(groupID: string | ClassInstanceType): string {
    return isString(groupID) ? groupID : groupID.constructor.name;
}

function canLog(logType?: LogLevelTypes): boolean {

    if (logType === 'error') {
        return true;
    }

    if (global && (global as IGlobalAngular).ngDevMode) {
        return true;
    }

    if (global && global.constructor && global.constructor.name !== 'Window') {
        return true;
    }

    return false;
}


function getGlobalObject(): IGlobalAngular & (Window & typeof globalThis) {
    if (typeof globalThis !== 'undefined') {
        return globalThis as any;
    }
    if (typeof self !== 'undefined') {
        return self;
    }
    if (typeof window !== 'undefined') {
        return window;
    }
    if (typeof ['global'] as unknown !== 'undefined') {
        return ['global'] as any;
    }
    throw new Error('cannot find the global object');
}


function getServiceIcon(originName: string) {

    originName = originName.toLowerCase();

    if (originName.includes('command')) {
        return bullEmoji;
    }

    if (originName.includes('resolver')) {
        return cakeEmoji;
    }

    if (originName.includes('guard')) {
        return lockEmoji;
    }


    return unicornEmoji;
}

function getViewIcon(originName: string) {

    originName = originName.toLowerCase();

    if (originName.includes('mediator')) {
        return theaterEmoji;
    }

    return flowersEmoji;
}

import { ApiModule, Configuration } from 'src/codegen';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TUI_SANITIZER, TuiAlertModule, TuiDialogModule, TuiRootModule, TuiTextfieldControllerModule } from "@taiga-ui/core";
import {
    faAddressBook,
    faAddressCard,
    faArrowAltSquareDown,
    faArrowLeft,
    faArrowsRotate,
    faCalendarStar,
    faCircleChevronUp,
    faCopy,
    faEllipsisH,
    faEye,
    faEyeSlash,
    faIdCard,
    faListTree,
    faPeopleGroup,
    faShareAlt,
    faUserPlus,
    faBars as farBars,
    faCalendar as farCalendar,
    faFloppyDisk as farFloppyDisk,
    faHouse as farHouse,
    faIdBadge as farIdBadge,
    faPlus as farPlus,
    faSyncAlt as farSyncAlt,
    faTimes as farTimes,
    faTrash as farTrash
} from './../../local/@fortawesome/pro-regular-svg-icons';
import {
    faAnalytics,
    faAngleDown,
    faBars,
    faBookmark,
    faBriefcase,
    faBrush,
    faCalendar,
    faChartSimple,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faExternalLink,
    faFemale,
    faFireAlt,
    faFlag,
    faGear,
    faHouse,
    faIcons,
    faIdBadge,
    faLink,
    faLocationDot,
    faLock,
    faMale,
    faMedal,
    faMinus,
    faMusic,
    faPalette,
    faPen,
    faPercent,
    faPlus,
    faPlusSquare,
    faPowerOff,
    faSearch,
    faStar,
    faSync,
    faTasks,
    faTimes,
    faTrophy,
    faUser,
    faUserAstronaut,
    faUserCircle,
    faUserGraduate,
    faUserTie,
    faAddressBook as falAddressBook,
    faEnvelope as falEnvelope,
    faListTree as falListTree
} from './../../local/@fortawesome/pro-light-svg-icons';
import {
	faAppStore,
	faApple,
	faApplePay,
	faBehance,
	faDailymotion,
	faDeezer,
	faDev,
	faDeviantart,
	faDiscord,
	faFacebookF,
	faGithub,
	faInstagram,
	faLinkedin,
	faLinkedinIn,
	faPaypal,
	faPlaystation,
	faShopify,
	faSoundcloud,
	faSpotify,
	faStripe,
	faTiktok,
	faTwitch,
	faTwitter,
	faUntappd,
	faWordpress,
	faXbox,
	faYoutube
} from '@fortawesome/free-brands-svg-icons';
import {
    faArrowDown,
    faCheck,
    faChevronDoubleUp,
    faChevronsUp,
    faCrown,
    faEllipsisV,
    faEnvelope,
    faList,
    faPhone,
    faQrcode,
    faSave,
    faShieldHalved,
    faTriangleExclamation,
    faBars as fasBars,
    faChevronDown as fasChevronDown,
    faEllipsisH as fasEllipsesH,
    faHouse as fasHouse,
    faPlus as fasPlus,
    faStar as fasStar,
    faTimes as fasTimes,
} from './../../local/@fortawesome/pro-solid-svg-icons';
import {
    faDesktop,
    faDisplay,
    faGlobe,
    faRainbow,
    faTextSize,
    faUsers,
    faUsersViewfinder,
    faAddressCard as fadAddressCard,
    faBars as fadBars,
    faChevronsUp as fadChevronsUp,
    faFloppyDisk as fadFloppyDisk,
    faIdBadge as fadIdBadge,
    faLink as fadLink,
    faQrcode as fadQrcode,
} from './../../local/@fortawesome/pro-duotone-svg-icons';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from "@angular/platform-browser";
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
//import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SwitchProfileList2Resolver } from './modules/switch/resolver/switch-profile-list2.resolver';
import { SwitchProfileResolver } from './modules/switch/resolver/switch-profile.resolver';
import { SwitchStore } from './modules/switch/store/switch.store';
import { environment } from 'src/environments/environment';
import { jwtInterceptorProvider } from './core/interceptors/jwt.interceptor';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TuiRootModule,
		
        RxReactiveFormsModule,

        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule,

        ApiModule.forRoot(apiConfig),

        // taiga
        TuiTextfieldControllerModule,
        TuiDialogModule,
        TuiAlertModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        [ jwtInterceptorProvider ],
        //NgxImageCompressService,
        //SplashScreenStateService
        //{provide: TUI_SANITIZER, useClass: NgDompurifySanitizer},

        SwitchStore,
		SwitchProfileList2Resolver,
		SwitchProfileResolver
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {

    constructor(library: FaIconLibrary) {
        library.addIcons(

            /// FAL
            falAddressBook,
            faAnalytics,
            faAngleDown,
            faBars,
            faBookmark,
            faBriefcase,
            faBrush,
            faCalendar,
			faChartSimple,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronUp,
            faEllipsisH,
            falEnvelope,
            faExternalLink,
            faFemale,
            faFireAlt,
            faFlag,
            faGear,
            faHouse,
            faIcons,
            faIdBadge,
            faLink,
            falListTree,
            faLock,
            faLocationDot,
            faMale,
            faMedal,
            faMinus,
            faMusic,
            faPalette,
            faPen,
            faPercent,
            faPlus,
            faPlusSquare,
            faPowerOff,
            faSearch,
            faSync,
            faStar,
            faTasks,
            faTimes,
            faTrophy,
            faUser,
            faUserAstronaut,
            faUserCircle,
            faUserPlus,
            faUserTie,
            faUserGraduate,

            /// FAS
            faArrowDown,
            faCheck,
            faChevronDoubleUp,
            faChevronsUp,
            faCrown,
            faEllipsisV,
            faEnvelope,
			faList,
            faPhone,
            faQrcode,
            faSave,
            faTriangleExclamation,
            fasBars,
            fasChevronDown,
            fasEllipsesH,
            fasHouse,
			fasPlus,
            fasStar,
            fasTimes,

            /// FAR
            faAddressBook,
            faAddressCard,
            faArrowAltSquareDown,
            faArrowLeft,
            faArrowsRotate,
			faCalendarStar,
            faCircleChevronUp,
            faCopy,
            faEye,
            faEyeSlash,
            faIdCard,
            faListTree,
            faPeopleGroup,
            faShareAlt,
            farBars,
            farCalendar,
            farFloppyDisk,
            farHouse,
            farIdBadge,
            farPlus,
            farSyncAlt,
            farTimes,
            farTrash,

            /// FAD
            faDesktop,
            faDisplay,
            faGlobe,
            faRainbow,
            faShieldHalved,
            faTextSize,
            faUsers,
            faUsersViewfinder,
            fadAddressCard,
            fadBars,
            fadChevronsUp,
            fadFloppyDisk,
            fadIdBadge,
            fadLink,
            fadQrcode,

            /// FAB
			faAppStore,
			faApple,
			faApplePay,
			faBehance,
			faDailymotion,
			faDeezer,
			faDev,
			faDeviantart,
			faDiscord,
			faGithub,
			faPaypal,
			faPlaystation,
			faShopify,
			faStripe,
			faTwitch,
			faUntappd,
			faWordpress,
			faXbox,
            faFacebookF,
            faInstagram,
            faLinkedin,
            faLinkedinIn,
            faSoundcloud,
            faSpotify,
            faTiktok,
            faTwitter,
            faYoutube
        );
    }
}

export function apiConfig() {
    return new Configuration({
        withCredentials: true,
        basePath: environment.apiCodeGenUrl,
    });
}

import { AuthResponse } from 'src/codegen';
import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const PROFILE_KEY = 'auth-profiles';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    constructor() { }

    signOut(): void {
		// clear session storage
        window.sessionStorage.clear();

		// clear tokens from local storage
        window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.removeItem(PROFILE_KEY);
    }

    saveToken(token: string): void {
        //window.sessionStorage.removeItem(TOKEN_KEY);
        //window.sessionStorage.setItem(TOKEN_KEY, token);
		window.localStorage.removeItem(TOKEN_KEY);
        window.localStorage.setItem(TOKEN_KEY, token);
    }

    getToken(): string | null {
        //return window.sessionStorage.getItem(TOKEN_KEY);
        return window.localStorage.getItem(TOKEN_KEY);
    }



    saveUser(user: AuthResponse): void {
        // window.sessionStorage.removeItem(USER_KEY);
        // window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
        window.localStorage.removeItem(USER_KEY);
        window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    getUser(): AuthResponse {
        //const user = window.sessionStorage.getItem(USER_KEY);
        const user = window.localStorage.getItem(USER_KEY);
        if (user) {
            return JSON.parse(user) as AuthResponse;
        }

        return null;
    }



    saveProfiles(profiles: any): void {
        //window.sessionStorage.removeItem(PROFILE_KEY);
        //window.sessionStorage.setItem(PROFILE_KEY, JSON.stringify(profiles));
        window.localStorage.removeItem(PROFILE_KEY);
        window.localStorage.setItem(PROFILE_KEY, JSON.stringify(profiles));
    }

    getProfiles(): any {
        //const profiles = window.sessionStorage.getItem(PROFILE_KEY);
		const profiles = window.localStorage.getItem(PROFILE_KEY);
        if (profiles) {
            return JSON.parse(profiles);
        }

        return {};
    }
}
import { APP_FEATURES, APP_PAGES } from "./feature-flags";
import { Roles, Tiers } from "src/codegen";

// Page options
export const TEAM_OPTIONS = {
	VIEW: 'VIEW',
	UPDATE_MEMBERS: 'ADD_MEMBER',
	UPDATE_BRANDS: 'ADD_BRAND',
	UPDATE_TEAM_PAGE: 'UPDATE_TEAM_PAGE',
	UPDATE_CONFERENCE_PAGES: 'ADD_CONFERENCE_PAGE',
};
type TeamOptionTypes = keyof typeof TEAM_OPTIONS;


export const ANALYTICS_OPTIONS = {
	VIEW_TOTAL: 'VIEW_TOTAL',
	VIEW_DAILY: 'VIEW_DAILY',
	VIEW_DAILY_INTERACTIONS: 'VIEW_DAILY_INTERACTIONS'
}
type AnalyticsOptionTypes = keyof typeof ANALYTICS_OPTIONS;


export const BRANDING_OPTIONS = {
	THEME: 'THEME',
	LOGO_BRAND: 'LOGO_BRAND',
	LOGO_QRCODE: 'LOGO_QRCODE',
	UPDATE_THEME: 'UPDATE_THEME',
	UPDATE_CUSTOM_COLOURS: 'UPDATE_CUSTOM_COLOURS',
	UPDATE_BRAND_LOGO: 'UPDATE_BRAND_LOGO',
	UPDATE_QRCODE_LOGO: 'UPDATE_QRCODE_LOGO',
	USE_PARENT_THEME: 'USE_PARENT_THEME',
	USE_PROFILEPIC_BG: 'USE_PROFILEPIC_BG'
};
type BrandingOptionTypes = keyof typeof BRANDING_OPTIONS;


export const BIO_OPTIONS = {
	VIEW: 'VIEW',
	UPDATE: 'UPDATE'
};
type BioOptionTypes = keyof typeof BIO_OPTIONS;


export const CONTACTS_OPTIONS = {
	VIEW: 'VIEW',
	UPDATE: 'UPDATE'
};
type ContactsOptionTypes = keyof typeof CONTACTS_OPTIONS;


export const CONTENT_OPTIONS = {
	VIEW: 'VIEW',
	UPDATE: 'UPDATE'
};
type ContentOptionTypes = keyof typeof CONTENT_OPTIONS;



type PageTypes = keyof typeof Tiers; // APP_PAGES;
type FeatureTypes = keyof typeof APP_FEATURES;
type RoleTypes = keyof typeof Roles;

export type OptionTypes = TeamOptionTypes | AnalyticsOptionTypes | BrandingOptionTypes | BioOptionTypes | ContactsOptionTypes | ContentOptionTypes;
type OptionFlags<T extends OptionTypes> = {
	[role in RoleTypes]?: {
		[option in T]?: boolean
	};
};


type PageOptionFlagsMap = {
	[page in PageTypes]?: {
		[APP_FEATURES.TEAM]?: OptionFlags<TeamOptionTypes>,
		[APP_FEATURES.ANALYTICS]?: OptionFlags<AnalyticsOptionTypes>,
		[APP_FEATURES.BRANDING]?: OptionFlags<BrandingOptionTypes>,
		[APP_FEATURES.BIO]?: OptionFlags<BioOptionTypes>,
		[APP_FEATURES.CONTACTS]?: OptionFlags<ContactsOptionTypes>,
		[APP_FEATURES.CONTENT]?: OptionFlags<ContentOptionTypes>	
	}
};

export const INDIVIDUAL_PAGE_FLAGS: PageOptionFlagsMap = {
	[Tiers.Basic]: { //[APP_PAGES.INDIVIDUAL_PAGE]: {
		BRANDING: {
			Admin: {
				THEME: true,
				LOGO_BRAND: true,
				LOGO_QRCODE: true
			},
			Contributor: {
				THEME: true,
				LOGO_BRAND: true
			}
		},
		BIO: {
			Admin: {
			},
			Contributor: {
			}
		}
	}
};

export const TEAM_PAGE_FLAGS: PageOptionFlagsMap = {
	[Tiers.Team]: { //[APP_PAGES.TEAM_PAGE]: {
		BRANDING: {
			Admin: {
				THEME: true,
				LOGO_BRAND: true
			},
		}
	}
};

export const CONFERENCE_PAGE_FLAGS: PageOptionFlagsMap = {
	[Tiers.ConferencePage]: { //[APP_PAGES.CONFERENCE_PAGE]: {
		
	}
};

const pageOptionsArray: PageOptionFlagsMap[] = [
	INDIVIDUAL_PAGE_FLAGS,
	CONFERENCE_PAGE_FLAGS,
	TEAM_PAGE_FLAGS
];








// hasPermission implementation...
export function hasFeature(pageType: PageTypes, feature: FeatureTypes): boolean {
	const pageFlags2 = pageOptionsArray.find(flags => flags[pageType]);
    if (!pageFlags2) {
		return false;
	}

    return !!pageFlags2[pageType]![feature] || false;
}

export function hasPermission(pageType: PageTypes, feature: FeatureTypes, role: RoleTypes, option: OptionTypes | string): boolean {
	const pageFlags = pageOptionsArray.find(flags => flags[pageType]);
    if (!pageFlags) {
		return false;
	}

    const featureFlags = pageFlags[pageType]![feature];
    if (!featureFlags) {
		return false;
	}

    const roleFlags = featureFlags[role];
	// if (roleFlags == Roles.Admin) {
	// 	return true;
	// }
	return roleFlags?.[option] || false;
}




// export const OPTION_FLAGS2: {
// 	[APP_FEATURES.TEAM]: OptionFlags<TeamOptionTypes>,
// 	[APP_FEATURES.ANALYTICS]?: OptionFlags<AnalyticsOptionTypes>,
// 	[APP_FEATURES.BRANDING]: OptionFlags<BrandingOptionTypes>,
// 	[APP_FEATURES.BIO]: OptionFlags<BioOptionTypes>,
// 	[APP_FEATURES.CONTACTS]: OptionFlags<ContactsOptionTypes>,
// 	[APP_FEATURES.CONTENT]: OptionFlags<ContentOptionTypes>
// } = {
// 	TEAM: {

// 	},
// 	BRANDING: {
// 		Admin: {
// 			UPDATE_BRAND_LOGO: true,
// 			UPDATE_QRCODE_LOGO: true,
// 			UPDATE_THEME: true,
// 			VIEW_BRAND_LOGO: true,
// 			VIEW_QRCODE_LOGO: true,
// 			VIEW_THEME: true
// 		},
// 		Contributor: {
// 		}
// 	},
// 	BIO: {
// 		Admin: {
// 		},
// 		Contributor: {
// 		}
// 	}
// };
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountService } from './api/account.service';
import { AnalyticsAdminService } from './api/analyticsAdmin.service';
import { AuthService } from './api/auth.service';
import { CardsAdminService } from './api/cardsAdmin.service';
import { ContactsService } from './api/contacts.service';
import { ContactsAdminService } from './api/contactsAdmin.service';
import { ElementPortalService } from './api/elementPortal.service';
import { ElementsService } from './api/elements.service';
import { ElementsAdminService } from './api/elementsAdmin.service';
import { ImageAdminService } from './api/imageAdmin.service';
import { IntegrationsService } from './api/integrations.service';
import { PermissionsService } from './api/permissions.service';
import { PingService } from './api/ping.service';
import { ProfileAdminService } from './api/profileAdmin.service';
import { ProfilePortalService } from './api/profilePortal.service';
import { ProfilePublicService } from './api/profilePublic.service';
import { ProfilesService } from './api/profiles.service';
import { SocialsAdminService } from './api/socialsAdmin.service';
import { TagsService } from './api/tags.service';
import { TalentsAdminService } from './api/talentsAdmin.service';
import { TypesService } from './api/types.service';
import { UserAccountsService } from './api/userAccounts.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountService,
    AnalyticsAdminService,
    AuthService,
    CardsAdminService,
    ContactsService,
    ContactsAdminService,
    ElementPortalService,
    ElementsService,
    ElementsAdminService,
    ImageAdminService,
    IntegrationsService,
    PermissionsService,
    PingService,
    ProfileAdminService,
    ProfilePortalService,
    ProfilePublicService,
    ProfilesService,
    SocialsAdminService,
    TagsService,
    TalentsAdminService,
    TypesService,
    UserAccountsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard  {

  	constructor(private router: Router) {}

  	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

		window.location.href = route.data['externalUrl'];
      	return true;

	}
}
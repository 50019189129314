/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ConferenceProfileLinkTypes = 'TeamMember' | 'Speaker' | 'Sponsor' | 'Exhibitor' | 'Participant';

export const ConferenceProfileLinkTypes = {
    TeamMember: 'TeamMember' as ConferenceProfileLinkTypes,
    Speaker: 'Speaker' as ConferenceProfileLinkTypes,
    Sponsor: 'Sponsor' as ConferenceProfileLinkTypes,
    Exhibitor: 'Exhibitor' as ConferenceProfileLinkTypes,
    Participant: 'Participant' as ConferenceProfileLinkTypes
};
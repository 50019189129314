/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ProfileFrontpageTypes = 'Poster' | 'Logo' | 'QrCode';

export const ProfileFrontpageTypes = {
    Poster: 'Poster' as ProfileFrontpageTypes,
    Logo: 'Logo' as ProfileFrontpageTypes,
    QrCode: 'QrCode' as ProfileFrontpageTypes
};
/**
 * Wow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type Statuses = 'Disabled' | 'Active' | 'Pending' | 'ActiveHiddenFromTeamPage';

export const Statuses = {
    Disabled: 'Disabled' as Statuses,
    Active: 'Active' as Statuses,
    Pending: 'Pending' as Statuses,
    ActiveHiddenFromTeamPage: 'ActiveHiddenFromTeamPage' as Statuses
};
import { ElementTypes } from 'src/codegen'

export class Pages {

	static get landing(): string { return `/landing` }
	static get onboard(): string { return `/onboarding` }

	static Switch = class {
		static get list(): string {
			return '/switch/list'
		}
	}

	static get cardBlocked(): string { return '/cards/blocked' }

	static get login(): string { return `/auth/login` }
	static loginWithEmail(email: string): string { return `/auth/login/${email}`; }
	static preview(pagename?: string, profileId?: string): string { return `/app/preview/${pagename}/${profileId}` }

	static get register(): string { return `/auth/register` }
	static Register = class {
		static confirm(userId?: string, token?: string): string { return `/auth/confirm/${userId}/${token}` }
	}

	static Onboard = class {
        static professional(): string { return `/onboard/professional` }
        static get professionalStep1(): string { return `/onboard/professional/step1` }
        static team(): string { return `/onboard/team` }
    }

	static get dashboard(): string { return `/app/dashboard` }
	static Dashboard = class {
		static location(pagename?: string, profileId?: string, tab?: string): string { return `${Pages.dashboard}/${pagename}/${profileId}/${tab}`; }
		static links(pagename?: string, profileId?: string): string { return Pages.Dashboard.location(pagename, profileId, "links"); }


		static socials(pagename?: string, profileId?: string): string { return `/app/content/${pagename}/${profileId}/socials`; }
		static socialsUpdate(pagename?: string, profileId?: string): string { return `/app/content/${pagename}/${profileId}/socials/update`; }



		static loadProfile(pagename?: string, profileId?: string): string { return `${Pages.dashboard}/${pagename}/${profileId}` }
		static preview(pagename?: string, profileId?: string): string { return `${Pages.dashboard}/${pagename}/${profileId}/preview` }

		static dashboard(pagename?: string): string { return `${Pages.dashboard}/${pagename}` }

		static aboutWithId(pagename?: string, profileId?: string): string { return `${Pages.dashboard}/${pagename}/${profileId}/design` }
		static profile(pagename?: string): string { return `${Pages.dashboard}/${pagename}/view` }
	}


	static Analytics = class {
		static home(pagename: string): string {
			return `/app/${pagename}/analytics`;
		}
	}

	static Bio = class {
		static detail(pagename?: string): string {
			return `/app/${pagename}/bio/detail`;
		}
	}

	static Cards = class {
		static list(pagename: string): string {
			return `/app/${pagename}/cards`;
		}
	}

	static Children = class {
		static list(pagename: string): string {
			return `/app/${pagename}/children/list`;
		}
		static eventsList(pagename: string): string {
			return `/app/${pagename}/children/events/list`;
		}
	}

	static ConferencePages = class {
		static create(pagename: string): string {
			return `/app/${pagename}/conference-pages/create/step1`;
		}

		static createStep2(pagename: string): string {
			return `/app/${pagename}/conference-pages/create/step2`;
		}

		static list(pagename: string): string {
			return `/app/${pagename}/conference-pages/list`;
		}
	}

	static Contacts = class {
		static list(pagename: string): string {
			return `/app/${pagename}/contacts`;
		}

		static create(pagename: string): string {
			return `${this.list(pagename)}/create`;
		}

		static edit(pagename: string, profileId: string, contactId: number): string {
			return `${this.list(pagename)}/${contactId}/edit`;
			//return `${ this.list(pagename, profileId) }/${ contactId }/edit`;
		}
	}


	static Content = class {
		static elementList(pagename: string): string {
			return `/app/content/${ pagename }/elements/list`;
		}

		static elementListAgenda(pagename: string): string {
			return `/app/content/${ pagename }/agenda/list`;
		}

		static list(pagename: string, profileId: string): string {
			return `/app/dashboard/${pagename}/${profileId}/links`;
		}
		
		static createEvent(pagename: string, profileId: string, eventType: ElementTypes = ElementTypes.Event): string {
			return `/app/content/${pagename}/${profileId}/${eventType}/create`;
		}

		static eventUpdate(pagename: string, profileId: string, eventType: ElementTypes = ElementTypes.Event, eventId: string): string {
			return `/app/content/${pagename}/${profileId}/${eventType}/${eventId}/update`;
		}

		static createProfileLink(pagename: string, profileId: string): string {
			return `/app/content/${pagename}/${profileId}/profile-link/create`;
		}

		static updateProfileLink(pagename: string, profileId: string, elementId: string): string {
			return `/app/content/${pagename}/${profileId}/profile-link/${elementId}/update`;
		}
	}


	static Design = class {
		static detail(pagename?: string): string {
			return `/app/${pagename}/design`;
		}

		static themeUpdate(pagename?: string): string {
			return `/app/${pagename}/design/update-theme`;
		}
	}


	static Integrations = class {
		static list(pagename?: string, profileId?: string) { return `/app/integrations/${pagename}/${profileId}` }
	}


	static Permissions = class {
		static list(pagename?: string, profileId?: string) { return `/app/permissions/${pagename}/${profileId}` }
		static create(pagename?: string, profileId?: string) { return `${this.list(pagename, profileId)}/invite` }
	}
}

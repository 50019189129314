import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TokenStorageService } from '../services/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization'; // for Spring Boot back-end

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    /// https://www.bezkoder.com/angular-11-jwt-auth/#Http_Interceptor

    constructor(private token: TokenStorageService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.token.getToken();
        if (token != null) {
            authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
        
        return next.handle(authReq);
    }
    
    
    // constructor(private authSvc: AuthService) { }
    
    // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //     let currentUser: AuthResponse;

    //     // complete after that we received one of the users, once it's completed, we are not subscribed anymore
    //     // take(1) guarantees to unsubscribe
    //     this.authSvc.currentUser$.pipe(take(1)).subscribe(
    //         user => currentUser = user
    //     );

    //     if (currentUser) {
    //         request = request.clone({
    //             setHeaders: {
    //                 Authorization: `Bearer ${currentUser.token}`
    //             }
    //         });
    //     }

    //     return next.handle(request);
    // }
}


export const jwtInterceptorProvider = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
];

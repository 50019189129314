import * as R from 'ramda';

export function isBoolean(value: unknown): value is boolean {
  return R.type(value) === 'Boolean';
}

export function isArray(value: unknown): value is Array<unknown> {
  return R.type(value) === 'Array';
}

export function isObject(value: unknown): value is { [P in string]: unknown } {
  return R.type(value) === 'Object';
}

export function isString(value: unknown): value is string {
  return R.type(value) === 'String';
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '@env';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    
    // for certain types of errors we'll be either routing the user to error page or show toast
    constructor() { }


    // we need to provide interceptor in app module
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // we'll need to catch any errors from this
        return next.handle(request).pipe(
            catchError(error => {
                
                if (error) {
                    switch (error.status) {

                        // // most tricky, as validation error returns object
                        // case 400:
                        //     // if(error.error.message) {
                        //     //     this.toastr.error(error.error.message);
                        //     // }

                        //     // HttpErrorResponse > error > errors
                        //     if (error.error.errors) {
                        //         const modalStateErrors: any = [];
                        //         for (const key in error.error.errors) {
                        //             if (error.error.errors[key]) {
                        //                 modalStateErrors.push(error.error.errors[key]);
                        //             }
                        //         }
                        //         // this requires es2019 to be added in the tsconfig
                        //         throw modalStateErrors.flat();
                        //     }
                            
                        //     else {
                        //         console.log(error.statusText === "OK" ? "Bad Request" : error.statusText, error.status);
                        //     }
                        //     break;

                        case 401:
							window.location.href = environment.wowUrl + 'login';
                            console.log(error.statusText === "OK" ? "Unauthorised" : error.statusText, error.status);

                            //this.portalAuthSvc.logout();
                            break;

                        // case 404:
                        //     console.log(`The page you requested was not found - ${error.statusText}`);
                        //     //this.router.navigateByUrl('/404');
                        //     break;

                        // case 500:
                        //     console.log("A server error occurred. Please contact your admin", error.statusText);
                        //     //const navigationExtras: NavigationExtras = { state: { error: error.error } };
                        //     //this.router.navigateByUrl('/server-error', navigationExtras);
                        //     break;

                        default:
                            //console.log('Something unexpected went wrong');
                            console.log(error);
                            break;
                    }
                }

                // we should never hit this
                return throwError(error);
            })
        );
    }
}

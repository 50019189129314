import { APP_FEATURES, FEATURE_FLAGS } from '../../../core/flags/feature-flags';
import { BehaviorSubject, of } from 'rxjs';
import { CardsAdminService, ProfileAdminService, ProfileResponse, ProfileResponseLists, Tiers } from 'src/codegen';
import { OptionTypes, hasPermission } from 'src/app/core/flags/permission-flags';
import { take, tap } from 'rxjs/operators';

import { AbstractReactiveModel } from '../../../core/reactive-model/abstract-reactive-model';
import { Injectable } from '@angular/core';
import { Logger } from 'src/app/core/logger/logger';

interface SwitchModel {
    profileLists?: ProfileResponseLists;
    profilesListCount: number;

    // previous
    profiles?: ProfileResponse[];

    currentProfile?: ProfileResponse;
	currentPagename?: string;
    currentProfileId?: string;
    currentProfileLink?: string;
	currentFeatures?;
	
	currentParentProfileId?: string;
	currentParentProfilePagename?: string;

    currentParentProfile?: ProfileResponse;
}

@Injectable()
export class SwitchStore extends AbstractReactiveModel<SwitchModel> {

	LOCAL_CURRENT_PROFILE = 'switch.currentProfile';
	LOCAL_CURRENT_PARENT_PAGENAME = 'switch.currentParentPagename';

    constructor(public profileAdminSvc: ProfileAdminService, public cardAdminService: CardsAdminService) {
        super({
            profileLists: {},
            profilesListCount: 0,
            
			profiles: [],

            currentProfile: {},
			currentFeatures: FEATURE_FLAGS[Tiers.Basic],

            currentParentProfile: {}
        });

        this.onModelUpdate.subscribe((updateModelVO) => {
            console.log(updateModelVO);
            localStorage.setItem("SwitchModel", JSON.stringify(updateModelVO.newValue));
            //this.saveToStorage(updateModelVO.newValue);
        });
    }


	getCurrentProfileLink(profile?: ProfileResponse) {
		if(!profile) {
			profile = this.get.currentProfile;
		}
		
		switch (profile.subscriptionTierId) {
			case Tiers.Premium:
			case Tiers.Team:
				return `https://${profile.pagename}.card.mt`;

			case Tiers.TeamMember:
				const pagenameSplit = profile.pagename.split('@');
				const parentName = pagenameSplit.length > 1 ? pagenameSplit[1] + '.' : '';
				const memberName = pagenameSplit[0];
				return `https://${parentName}card.mt/${memberName}`;

			default:
				return `https://wow.mt/${profile.pagename}`;
		}
	}

	
	
    getCurrentProfile() {
        const profileInStorage: ProfileResponse = JSON.parse(localStorage.getItem(this.LOCAL_CURRENT_PROFILE)) ?? {};
		this.setCurrentProfile(profileInStorage);

        return this.get.currentProfile;
    }

    setCurrentProfile(profile: ProfileResponse) {
		// TEMP: should be retrieved from API and this removed
		if ((profile?.subscription?.expiryDaysLeft ?? 0) < -5) {
			profile.subscriptionTierId = Tiers.Basic;
		}
		// END TEMP

		// setup current profile
        this.set.currentProfile = profile;
        this.set.currentProfileId = profile?.id;
		this.set.currentProfileLink = this.getCurrentProfileLink(profile);
        this.set.currentPagename = profile?.pagename;
		this.set.currentFeatures = FEATURE_FLAGS[profile.tierId];
        localStorage.setItem(this.LOCAL_CURRENT_PROFILE, JSON.stringify(profile));
		
		// setup parent profile
		this.set.currentParentProfileId = profile?.parentProfileId;
		this.set.currentParentProfilePagename = profile?.parentProfileName;
        localStorage.setItem(this.LOCAL_CURRENT_PARENT_PAGENAME, profile?.parentProfileName);
    }

	getCurrentParentPagename() {
		return this.get.currentParentProfilePagename
			?? localStorage.getItem(this.LOCAL_CURRENT_PARENT_PAGENAME);
	}



    getProfileById(profileId: string) {
        if(profileId == this.get.currentProfileId) { // && !!this.get.currentProfile?.id
            return of(this.get.currentProfile);
        }

        return this.profileAdminSvc.getByProfileId(profileId).pipe(
            take(1),
            tap((profile: ProfileResponse) => {
                this.setCurrentProfile(profile);
            })
        );
    }

    getProfileByPagename(pagename: string) {
        if(pagename == this.get.currentProfile?.pagename) { // && !!this.get.currentProfile?.id
            return of(this.get.currentProfile);
        }

        return this.profileAdminSvc.getByProfilePagename(pagename).pipe(
            take(1),
            tap((profile: ProfileResponse) => {
                this.setCurrentProfile(profile);
            })
        );
    }

	setCardToProfile(profile: ProfileResponse, emptyCardId: string) {
		return this.cardAdminService.linkCard(profile.id, emptyCardId);
        //return this.profileAdminSvc.setCardToProfile(profile.id, emptyCardId);
	}
	

    clearCurrentProfile() {
        localStorage.removeItem(this.LOCAL_CURRENT_PROFILE);
        this.set.currentProfile = undefined;
        this.set.currentProfileId = undefined;
        this.set.currentPagename = undefined;
    }



    fetchProfileLists() {
        return this.profileAdminSvc.getProfileLists().pipe(
            //take(1),
            tap(
				(next: ProfileResponseLists) => {
					console.log("this.set.profileLists", this.get.profileLists);
					this.set.profileLists = next;
            	}
			)
        );
    }

	hasMultipleProfiles() {
		const storedCount = +(localStorage.getItem("profilesCount") ?? '0');
		const lists = this.get.profileLists;
		return ((lists?.owned?.length ?? 0) + (lists?.shared?.length ?? 0)) > 1 || storedCount > 1;
	}


    // // TODO: Remove and use fetchProfileLists()
    // fetchProfiles() {
    //     return this.profileAdminSvc.getList().pipe(
    //         take(1),
    //         tap((next: ProfileResponseBody) => {

    //             Logger.logServiceMessage(`Fetched Profiles`, this);
    //             this.set.profiles = next.body ?? [];
    //             this.set.profilesListCount = next.body.length;

    //             localStorage.setItem("switch.profilesListCount", this.get.profilesListCount?.toString() ?? "0");

    //             if(this.get.profiles?.length == 1) {
    //                 this.setCurrentProfile(this.get.profiles[0]);
    //             }
    //         })
    //     );
    // }


	
	verifyFeature(feature: keyof typeof APP_FEATURES) {
		const tierFlags = FEATURE_FLAGS[this.get.currentProfile.tierId];
        if (!tierFlags) {
			return false; // Tier not found, feature is disabled by default
		}

        const featureEnabled = tierFlags[feature];
        return featureEnabled === undefined
			? false
			: featureEnabled;
	}

	verifyPermission(feature: keyof typeof APP_FEATURES, option: OptionTypes | string) {
		const pageType = this.get.currentProfile.tierId;
        if (!pageType) {
			return false; // Tier not found, feature is disabled by default
		}

		return hasPermission(pageType, feature, 'Admin', option);
	}
}
